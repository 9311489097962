<template>
    <div class="modal fade" v-bind:id="id" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog">
              <div class="modal-content">
                <form @submit.prevent="save">
                <div class="modal-header">
                  <h1 class="modal-title fs-5" id="staticBackdropLabel">Modifica Veicolo (venditori)</h1>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" v-bind:id="id+'closeButton'"></button>
                </div>
                
                <div class="modal-body" v-if="veicolo">
                    <div class="mb-3 bg-secondary text-white rounded p-3">
                        <span class="fw-bolder">ID gestionale</span>: {{ veicolo.id_gestionale }}<br>
                        <span class="fw-bolder">Telaio</span>: {{ veicolo.telaio }}<br>
                        <span class="fw-bolder">Targa</span>: {{ veicolo.targa }}<br>
                        <span class="fw-bolder">Marca</span>: {{ veicolo.marca }} <br>
                        <span class="fw-bolder">Modello</span>: {{ veicolo.modello }}<br>
                        </div>
                        
                        <div class="mb-3">
                          <h4>Area</h4>
                          <label for="area" class="form-label">Area</label>
                        <select class="form-select" name="area" v-model="id_area" required>
                            <option v-for="area in aree" :value="area.id" :key="area.id">
                                {{ area.descrizione }}
                            </option>
                        </select>
                        </div>
                        <div class="mb-3">
                          <h4>Codice chiave</h4>
                          <label for="chiave" class="form-label">Codice</label>
                          <input type="text" class="form-control" name="codice" v-model="codiceChiave" maxlength="4" required/>
                        </div>
                      
                </div>
              
                <div class="modal-footer">
                  <button type="submit" class="btn btn-primary">Salva</button>
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Chiudi</button>
                </div>
              </form>
              </div>
            </div>
          </div>
    </template>
    
    <script>    
    import veicoliService from '@/services/veicoli.service';

    export default {
      name: 'DealersNotesModal',
      props: ['id', 'veicolo', 'aree'], //id used for modal idetnifier
      data(){
        return {
            id_area: null,
            codiceChiave: null,
        }
      },

      watch: { 
        veicolo: function(newVal) { // watch it
          this.id_area = newVal.id_area;
          this.codiceChiave = newVal.codiceChiave;
        }
      },
    
      methods: {
        save(){
            veicoliService.updateVeicolo(this.veicolo.id, {
              id_area: this.id_area,
              codiceChiave: this.codiceChiave
            }).then(() => {
              //console.log("Note saved!");
              document.getElementById(this.id + "closeButton").click();
              this.$emit('success');
            });
        }
      }
    }
    </script>
    