<template>
    <div>
      <h1>{{computedTitle}}</h1>
      <div class="d-flex flex-row-reverse">
        <div class="p-2"><input type="text" v-model="searchValue" placeholder="Search Value"></div>
        <!--<div class="p-2">Flex item 1</div>
        <div class="p-2">Flex item 2</div>-->
      </div>
      <div></div>
      <DataTable 
        :headers="headers" 
        :items="items"
        :search-value="searchValue"
        :search-field="searchColumns"
        :loading="loading_"
        alternating
      >
        <template #loading>
            <img src="https://i.pinimg.com/originals/94/fd/2b/94fd2bf50097ade743220761f41693d5.gif" style="width: 100px; height: 80px;" />
        </template>
        <template #item-waitMagazzino="item">
          <button type="button" class="btn bi bi-box-fill btn-sm" :class="magazColor(item)" v-if="[5,6].includes(roleId)" data-bs-toggle="modal" data-bs-target="#magazzinoModal" @click="select(item, $refs.magazModal.loadRichieste)">&nbsp; {{ item.magazCompleted }} di {{ item.magaz }}</button>
        </template>
        <template #item-esterna="item">
          {{ (item.ubicazione ? item.ubicazione.ragioneSociale : "") }}
        </template>
        <template #item-operation="item">
            <div class="btn-group btn-sm">
                <button type="button" class="btn btn-outline-secondary bi bi-check-circle btn-sm" v-if="[3,5].includes(roleId)" data-bs-toggle="modal" data-bs-target="#terminaModal" @click="select(item)" title="Termina"></button>
                <button type="button" class="btn btn-outline-secondary bi bi-droplet-half btn-sm" v-if="[4,5,6].includes(roleId)" @click="select(item)" data-bs-toggle="modal" data-bs-target="#lavaggioModal" title="Richiedi lavaggio"></button>
                <button type="button" class="btn btn-outline-secondary bi bi-box-arrow-up-right btn-sm" v-if="[3,5,6].includes(roleId) && !item.dataPrevistoRientro" data-bs-toggle="modal" data-bs-target="#esternaModal" @click="select(item)" title="Effettua lavoro esternamente"></button>
                <button type="button" class="btn btn-outline-secondary bi bi-eye btn-sm" v-if="[3,5,6].includes(roleId)" data-bs-toggle="modal" data-bs-target="#viewModal" @click="select(item)" title="Dettaglio"></button>
                <button type="button" class="btn btn-outline-secondary bi bi-bookmark-fill btn-sm" data-bs-toggle="modal" data-bs-target="#noteM" @click="select(item)" />
              </div>
        </template>
      </DataTable>

      <NoteModal :veicolo="selectedItem ? selectedItem.veicolo : null" id="noteM" @success="fetchData()"></NoteModal>
      <EsternaModal id="esternaModal" v-bind:item="selectedItem" title="Esegui lavoro esternamente" @success="fetchData()"/>
      <TerminateModal id="terminaModal" v-bind:item="selectedItem" title="Termina lavoro " @success="removeFromList(selectedItem)"/>
      <LavaggioModal v-if="[4,5,6].includes(roleId)" id="lavaggioModal" v-bind:item="selectedItem" @success="removeFromList(selectedItem)"/>
      <RichiesteMagazzino ref="magazModal" v-bind:item="selectedItem" title="Richiesta magazzino" @success="fetchData()"/>
      <ViewLavoroModal id="viewModal" v-bind:item="selectedItem"/>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import ViewLavoroModal from "@/components/ViewLavoroModal.vue";
  import lavoriService from "@/services/lavori.service";
  import EsternaModal from "@/components/EsternaModal.vue";
  import TerminateModal from "@/components/TerminateModal.vue";
  import LavaggioModal from "@/components/LavaggioModal.vue";
  import RichiesteMagazzino from "@/components/RichiesteMagazzino.vue";
  import NoteModal from "@/components/NoteModal.vue";
  import bootstrap from "bootstrap";

  export default {

    name: 'TableView', 
    components: {
    EsternaModal,
    TerminateModal,
    LavaggioModal,
    RichiesteMagazzino,
    ViewLavoroModal,
    NoteModal
},
    created(){
     
    },
    data() {
        return {
        today: new Date().getTime(),
        loading_:true,
        searchValue: "",
        headers: [
          { text: "Operazioni", value: "operation"},
          { text: "ID gestionale", value: "veicolo.id_gestionale", sortable: true },
          { text: "Telaio", value: "veicolo.telaio", sortable: true },
          { text: "Targa", value: "veicolo.targa", sortable: true },
          { text: "Marca", value: "veicolo.marca", sortable: true },
          { text: "Modello", value: "veicolo.modello", sortable: true },
          { text: "Note", value: "veicolo.note", width: 150},
          { text: "Km", value: "veicolo.km", sortable: true },
          { text: "Colore", value: "veicolo.colore", sortable: true },
          { text: "Data Contratto", value: "veicolo.dataContratto", sortable: true },
          { text: "GIF", value: "gif", sortable: true, width: 30},
          { text: "Magazzino", value: "waitMagazzino", sortable:true},
          { text: "Esterna", value: "esterna", sortable:true},
          { text: "Previsto Rientro", value: "dataPrevistoRientro", sortable:true}
          
        ],
        items: [],

        selectedItem: null,
        searchColumns: ["veicolo.telaio", "veicolo.targa", "veicolo.modello", "veicolo.marca", "veicolo.colore", "esterna"]
        }
    },
    mounted(){
      this.fetchData();
      var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
      popoverTriggerList.map(function (popoverTriggerEl) {
        return new bootstrap.Popover(popoverTriggerEl)
      });
    },
    computed : {
      roleId(){
        return this.$store.state.auth.user.role.id;
      },
      computedTitle(){
        switch(this.roleId){
          case 3: return "Lavaggio";
          case 4: return "Foto";
          case 5: return "Carrozzeria";
          case 6: return "Meccanica";
          default: return ""; 
        }
      }
    }, 
    methods : {
        select(item, callback){
          this.selectedItem = item;
          if (callback) callback(item);
        },

        removeFromList(i){
          this.items.splice(this.items.indexOf(i), 1);
        },

        magazColor(m){
          if (m.magaz == 0) return 'btn-outline-secondary btn-light';
          return ((m.magazCompleted != m.magaz) ? 'btn-danger' : 'btn-success');
        },

        fetchData(){
          lavoriService.getLavoriByRole(this.roleId).then(response =>{
        this.items = response.data;
        this.items.forEach(it => {
          it.gif = this.difference(it.dataInizio); 
          it.esterna = it.ubicazione != null;
          if (!it.veicolo.dataContratto) it.veicolo.dataContratto = "";
          if (!it.dataPrevistoRientro) it.dataPrevistoRientro = ""; 
        });
        this.loading_ = false;
      });
        },

        difference(date) {
          var day = new Date(date);
          return Math.ceil((this.today - day.getTime())/ (1000 * 60 * 60 * 24));
        }
    }
  }
  </script>
  