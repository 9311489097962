<template>
  <div class="modal fade" v-bind:id="id" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h1 class="modal-title fs-5" id="staticBackdropLabel" v-if="add"> Aggiungi Fornitore</h1>
                  <h1 class="modal-title fs-5" id="staticBackdropLabel" v-else> Modifica Fornitore</h1>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" v-bind:id="id+'closeBtn'"></button>
                </div>
                <form @submit.prevent="save">
                <div class="modal-body">
                  <div class="mb-3">
                            <label for="ragioneSociale" class="form-label">Ragione sociale</label>
                            <input type="text" class="form-control" name="ragioneSociale" v-model="fornitore.ragioneSociale" required/>
                        </div>
                        <div class="mb-3">
                            <label for="descrizione" class="form-label">Descrizione</label>
                            <input class="form-control" type="text" name="deascrizione" v-model="fornitore.descrizione" required/>
                        </div>
                        <div class="mb-3">
                            <label for="indirizzo" class="form-label">Indirizzo</label>
                            <input class="form-control" type="text" name="indirizzo" v-model="fornitore.indirizzo"/>
                        </div>
                        <div class="mb-3">
                            <label for="email" class="form-label">Email</label>
                            <input class="form-control" type="email" name="email" v-model="fornitore.email" required/>
                        </div>
                        <div class="mb-3">
                            <label for="telefono" class="form-label">Telefono</label>
                            <input class="form-control" type="text" name="telefono" v-model="fornitore.telefono" required/>
                        </div>
                        <div class="mb-3">
                            <label for="piva" class="form-label">P.IVA</label>
                            <input class="form-control" type="text" name="piva" v-model="fornitore.piva"/>
                        </div>
                        <div class="mb-3">
                            <label for="rif" class="form-label">Riferimento</label>
                            <input class="form-control" type="text" name="rif" v-model="fornitore.riferimento"/>
                        </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Chiudi</button>
                  <button type="submit" class="btn btn-primary">Aggiungi</button>
                 
                </div>
            </form>
              </div>
            </div>
          </div>
</template>
<script>
import ubicazioniService from '@/services/ubicazioni.service';

export default {
  name: 'FornitoreModal',
  props: ['id', 'add', 'item'],
  data(){
    return {
        fornitore: {}, 

    }
  },
  watch: {
    item(newItem){
      this.fornitore = newItem;
      }
  },
  mounted(){

  },

  methods: {
    save(){
        if (this.add){
          // add
          ubicazioniService.insertUbicazione(this.fornitore).then(() => {
            document.getElementById(this.id + 'closeBtn').click();
            this.fornitore = {};
            this.$emit('success');
          }).catch(error => {
              console.log(error);
          });

        } else {
          // update
          /*lavoriService.updateLavoro(this.lavoro.id, {
            descrizione: this.lavoro.descrizione,
          }).then(() => {
            console.log("UPDATE Successful");
            document.getElementById(this.id + 'closeBtn').click();
            this.$emit('success');
          }).catch(error => {
              console.log(error);
          });*/
        }
    },
  }
}
</script>