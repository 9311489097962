<template>
    <div class="modal fade" v-bind:id="id" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h1 class="modal-title fs-5" id="staticBackdropLabel">{{title}}</h1>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" v-bind:id="id+'closeButton'"></button>
                </div>
                
                <div class="modal-body" v-if="item">
                        <div class="mb-3">
                            Eliminare il lavoro selezionato?
                        </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">No</button>
                  <button type="button" class="btn btn-primary" @click="save">Si</button>
                </div>
              </div>
            </div>
          </div>
    </template>
    
    <script>
    import lavoriService from '@/services/lavori.service';
    
    export default {
      name: 'DeleteModal',
      props: ['id', 'item', 'title'],
      data(){
        return {
            
        }
      },
    
      methods: {
        save(){
          lavoriService.deleteLavoro(this.item.id).then(() => {
              document.getElementById(this.id+'closeButton').click();
              this.$emit('success');
            });
        }
      }
    }
    </script>
    