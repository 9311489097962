<template>
    <div class="modal fade" v-bind:id="id" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h1 class="modal-title fs-5" id="staticBackdropLabel">{{title}}</h1>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" v-bind:id="id+'closeButton'"></button>
                </div>
                
                <div class="modal-body">
                        <div class="mb-3">
                            Aggiungere Lavaggio + Foto?
                        </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">No</button>
                  <button type="button" class="btn btn-primary" @click="save">Si</button>
                </div>
              </div>
            </div>
          </div>
    </template>
    
    <script>
    import lavoriService from '@/services/lavori.service';
    
    export default {
      name: 'FastAddLavaggioFotoModal',
      props: ['id', 'veicolo_id', 'title'],
      data(){
        return {
            
        }
      },
    
      methods: {
        runSerial(tasks) {
          var result = Promise.resolve();
          tasks.forEach(task => {
            result = result.then(() => task());
          });
          return result;
        },
        save(){
          
          lavoriService.insertLavoro({
            veicolo_id: this.veicolo_id,
            lavorazione_id: 1,
          }).then(() => {
            return lavoriService.insertLavoro({
            veicolo_id: this.veicolo_id,
            lavorazione_id: 2,
            })
          }).then(() => {
            document.getElementById(this.id + 'closeButton').click();
            this.$emit('success');
          });


         
        }
      }
    }
    </script>
    