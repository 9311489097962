<template>
<div class="modal fade" v-bind:id="id" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="staticBackdropLabel">{{title}}</h1>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" v-bind:id="id+'closeBtn'"></button>
            </div>
            <form @submit.prevent="save">
            <div class="modal-body">
                <div class="mb-3 bg-secondary text-white rounded p-3" v-if="item && item.veicolo">
                    <span class="fw-bolder">ID gestionale</span>: {{ item.veicolo.id_gestionale }}<br>
                    <span class="fw-bolder">Telaio</span>: {{ item.veicolo.telaio }}<br>
                    <span class="fw-bolder">Targa</span>: {{ item.veicolo.targa }}<br>
                    <span class="fw-bolder">Marca</span>: {{ item.veicolo.marca }} <br>
                    <span class="fw-bolder">Modello</span>: {{ item.veicolo.modello }}<br>
                    </div>
                    <div class="mb-3">
                        <label for="destinazione" class="form-label">Destinazione</label>
                        <select class="form-select" name="destinazione" v-model="selectedUbicazioneID" required>
                            <option v-for="ubicazione in ubicazioni" :value="ubicazione.id" :key="ubicazione.id">
                                {{ ubicazione.ragioneSociale }}
                            </option>
                        </select><br>
                        <button class="btn btn-outline-secondary btn-sm bi bi-person-fill-add"  data-bs-toggle="modal" data-bs-target="#addFornitoreModal"> Aggiungi fornitore</button>
                    </div>
                    <div class="mb-3">
                        <label for="previstoRientro" class="form-label">Data previsto rientro</label>
                        <input type="date" class="form-control" name="previstoRientro" :min="todayString()" v-model="selectedDataPrevistoRientro" required>
                    </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Chiudi</button>
              <button type="submit" class="btn btn-primary">Salva</button>
            </div>
        </form>
          </div>
        </div>
      </div>
      <FornitoreModal id="addFornitoreModal" :add="true" @success="fetchUbicazioni()"></FornitoreModal>
</template>

<script>
import lavoriService from '@/services/lavori.service';
import FornitoreModal from './FornitoreModal.vue';

export default {
    name: "EsternaModal",
    props: ["id", "item", "title"],
    data() {
        return {
            ubicazioni: [],
            selectedUbicazioneID: null,
            selectedDataPrevistoRientro: null
        };
    },
    created() {
        this.fetchUbicazioni();

    },
    methods: {
        save() {
            lavoriService.updateLavoro(this.item.id, {
                esterno: true,
                ubicazione_id: this.selectedUbicazioneID,
                dataUscita: lavoriService.today(),
                dataPrevistoRientro: lavoriService.convertToISO(this.selectedDataPrevistoRientro)
            }).then(() => {
                document.getElementById(this.id + "closeBtn").click();
                this.$emit("success");
            });
        },
        fetchUbicazioni(){
            lavoriService.getUbicazioni().then(response => {
            this.ubicazioni = response.data;
        });
        },
        todayString(){
            var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth()+1; //January is 0!
        var yyyy = today.getFullYear();
        if(dd<10){
                dd='0'+dd
            } 
            if(mm<10){
                mm='0'+mm
            } 

        return yyyy+'-'+mm+'-'+dd;
        }
    },
    components: { FornitoreModal }
}
</script>
