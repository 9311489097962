<template>
  <div class="container-fluid vh-100" style="margin-top:150px">
    <div class="d-flex justify-content-center"><img :src="logo" height="100"/></div>
            <div class="" style="margin-top:30px">
                <div class="rounded d-flex justify-content-center">
                    <div class="col-md-4 col-sm-12 shadow-lg p-5 bg-light">
                        <div class="text-center">
                            <h3 class="text-primary">Login</h3>
                        </div>
                        <form v-on:submit.prevent="handleLogin">
                            <div class="p-4">
                                <div class="input-group mb-3">
                                    <span class="input-group-text bg-primary"><i
                                            class="bi bi-person-plus-fill text-white"></i></span>
                                    <input name="usernameAmbrosi" type="email" class="form-control" placeholder="E-Mail" v-model="username" />
                                </div>
                                <div class="input-group mb-3">
                                    <span class="input-group-text bg-primary"><i
                                            class="bi bi-key-fill text-white"></i></span>
                                    <input name="password" type="password" class="form-control" placeholder="password" v-model="password"/>
                                </div>
                                <button class="btn btn-primary text-center mt-2" type="submit">
                                    <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                                    <span>Login</span>
                                </button>
                                <p class="text-center text-primary"><a href="/password/resetRequest">Dimenticato la tua password?</a></p>
                                <div class="form-group pt-5">
                                  <div v-if="message" class="alert alert-danger" role="alert">
                                    {{ message }}
                                  </div>
                                </div>
                            </div>
                          </form>
                    </div>
                </div>
            </div>
        </div>
  </template>

  <script>

export default {
  name: "LoginView",
  data() {

    return {
      logo: require('../../public/ambrosi-logo@2x.webp'),
      username: "",
      password: "",
      loading: false,
      message: "",
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/");
    }

    if (this.$route.query.token){
      this.loading = true;
      this.$store.dispatch("auth/autologin", decodeURI(this.$route.query.token)).then(
        () => { this.$router.go("/");},
        () => {
          this.loading = false;
          this.message = "Invalid token!";
        }
      );
    }
  },
  methods: {
    handleLogin() {
      let user = {
        username: this.username,
        password: this.password
      }
      this.loading = true;

      this.$store.dispatch("auth/login", user).then(
        () => {
          this.$router.go("/");
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
  },
};
  </script>