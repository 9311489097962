<template>
    <h1>{{ name }}</h1>
    My email is {{ mail }}.
</template>

<script>
import axios from 'axios'
import authService from '@/services/auth.service';

const API_URL = 'http://localhost:8000/';

export default {
  name: 'MeInfo',
  data() {
    return {
        name: "",
        mail: "",
    }
  },
  mounted(){
    axios.get(API_URL + "me", {headers : authService.authHeader()}).then(response =>{
        console.log(response.data);
        this.name = response.data.name;
        this.mail = response.data.email;
    });
  }
}
</script>
