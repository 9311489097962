<template>
  <div class="modal modal-lg fade" v-bind:id="id" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h1 class="modal-title fs-5" id="staticBackdropLabel">Dettaglio Lavoro</h1>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" v-bind:id="id+'closeBtn'"></button>
                </div>
                <div class="modal-body">
                  <div class="mb-3 bg-secondary text-white rounded p-3" v-if="item">
                        <span class="fw-bolder">ID gestionale</span>: {{ item.veicolo.id_gestionale }}<br>
                        <span class="fw-bolder">Telaio</span>: {{ item.veicolo.telaio }}<br>
                        <span class="fw-bolder">Targa</span>: {{ item.veicolo.targa }}<br>
                        <span class="fw-bolder">Marca</span>: {{ item.veicolo.marca }} <br>
                        <span class="fw-bolder">Modello</span>: {{ item.veicolo.modello }}<br>
                  </div>
                        <div class="mb-3">
                          <span class="fw-bolder">Descrizione lavoro</span>:<br>
                            {{ lavoro.descrizione }}
                    
                        </div>
                        <div class="mb-3" v-if="lavoro.numeroCommessa">
                          <span class="form-label"><h5>Commessa</h5></span>
                          <div class="mb-3 rounded p-3 bg-light">
                            <span class="fw-bolder">Numero commessa</span>: {{ lavoro.numeroCommessa }}<br>
                            <span class="fw-bolder">Data documento</span>: {{ lavoro.dataDoc }}<br>
                            <span class="fw-bolder">Numero documento</span>: {{ lavoro.numeroDoc }}
                          </div>
                        </div>
                        <div class="mb-3" v-if="lavoro.ubicazione">
                          <span class="form-label"><h5>Lavoro esterno presso</h5></span>
                          <div class="mb-3 rounded p-3 bg-light">
                            <span class="fw-bolder">Ragione sociale</span>: {{ lavoro.ubicazione.ragioneSociale }}<br>
                            <span class="fw-bolder">Indirizzo</span>: {{ lavoro.ubicazione.indirizzo }}<br>
                            <span class="fw-bolder">Telefono</span>: <a :href="'tel:'+lavoro.ubicazione.telefono">{{ lavoro.ubicazione.telefono }}</a><br>
                            <span class="fw-bolder">Email</span>: <a :href="'mailto:'+lavoro.ubicazione.email">{{ lavoro.ubicazione.email }}</a><br>
                            <span class="fw-bolder">Riferimento</span>: {{ lavoro.ubicazione.riferimento }}<br><br>

                            <span class="fw-bolder">Previsto Rientro</span>: <u>{{ lavoro.dataPrevistoRientro }}</u>
                          </div>
                        </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Chiudi</button> 
                </div>
              </div>
            </div>
          </div>
</template>
<script>
import lavoriService from '@/services/lavori.service';

export default {
  name: 'ViewLavoroModal',
  props: ['id', 'item'],
  data(){
    return {
        richieste: [],
        lavorazioni: [],
        lavoro: {
          lavorazione: {}
        }, 
    }
  },
  watch: {
    item(newItem){
      this.lavoro = newItem;
      }
  },
  mounted(){

    lavoriService.getLavorazioni().then(response => {
      this.lavorazioni = response.data;
    });
  },
  computed: {
  },

  methods: {
  }
}
</script>