<template>
  <div class="home">
    <img alt="Vue logo" src="../assets/logo.png">
    <MeInfo />
    <HelloWorld msg="Welcome to Your Vue.js App"/>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import MeInfo from '@/components/MeInfo.vue';

export default {
  name: 'HomeView',
  components: {
    HelloWorld, MeInfo
  },
  created(){
    if (!this.$store.state.auth.loggedIn) {
      this.$router.push("/login");
    }
  }
}
</script>
