import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import "bootstrap-icons/font/bootstrap-icons.css"

// used to render tables
import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';


// gestisce tutte le unauthorized
axios.interceptors.response.use(response => response, error => {
    //console.log(error.response.data);
    if (error.response.status == 401){
        store.dispatch('auth/logout');
        router.push('/login');
    }
    return Promise.reject(error);
});



createApp(App).use(store).use(router).component('DataTable', Vue3EasyDataTable).mount('#app')

                        
