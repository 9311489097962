<template>
    <div class="modal fade" v-bind:id="id" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h1 class="modal-title fs-5" id="staticBackdropLabel">Richiesta lavaggio</h1>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" v-bind:id="id+'closeButton'" ></button>
                </div>
                <form @submit.prevent="save">
                <div class="modal-body">
                    <div class="mb-3 bg-secondary text-white rounded p-3" v-if="item">
                        <span class="fw-bolder">ID gestionale</span>: {{ item.veicolo.id_gestionale }}<br>
                        <span class="fw-bolder">Telaio</span>: {{ item.veicolo.telaio }}<br>
                        <span class="fw-bolder">Targa</span>: {{ item.veicolo.targa }}<br>
                        <span class="fw-bolder">Marca</span>: {{ item.veicolo.marca }} <br>
                        <span class="fw-bolder">Modello</span>: {{ item.veicolo.modello }}<br>
                        </div>
                        <div class="mb-3">
                            <label for="descrizione" class="form-label">Descrizione (obbligatorio)</label>
                            <textarea class="form-control" v-model="descrizione" required></textarea>
                        </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Chiudi</button>
                  <button type="submit" class="btn btn-primary">Richiedi</button>
                </div>
            </form>
              </div>
            </div>
          </div>
    </template>
    
    <script>
    import lavoriService from '@/services/lavori.service';
    export default {
      name: 'LavaggioModal',
      props: ['id', 'item', 'title'],
      data(){
        return {
            descrizione: null,
        }
      },
    
      methods: {
        save(){
            lavoriService.insertLavoro({
              veicolo_id: this.item.veicolo.id,
              lavorazione_id: 1, // lavaggio 1, per altri lavori cambiare il numero
              descrizione: this.descrizione,
              priority: this.item.priority
            }).then(() => {
              lavoriService.updateLavoro(this.item.id, {
                  dataInizio: null
              }).then(() => {
                document.getElementById(this.id+'closeButton').click();
                this.$emit('success');
              });
            });

            
        }
      }
    }
    </script>
    