<template>
  <div class="container-fluid vh-100" style="margin-top:150px">
    <div class="d-flex justify-content-center"><img :src="logo" height="100"/></div>
            <div class="" style="margin-top:30px">
                <div class="rounded d-flex justify-content-center">
                    <div class="col-md-4 col-sm-12 shadow-lg p-5 bg-light">
                        <div class="text-center">
                            <h3 class="text-primary">Password Reset</h3>
                        </div>
                        <form v-on:submit.prevent="handleReset">
                            <div class="p-4">
                                <div class="input-group mb-3">
                                    <span class="input-group-text bg-primary"><i
                                            class="bi bi-key-fill text-white"></i></span>
                                    <input name="password1" type="password" class="form-control" placeholder="Password" v-model="password1" />
                                </div>
                                <div class="input-group mb-3">
                                    <span class="input-group-text bg-primary"><i
                                            class="bi bi-key-fill text-white"></i></span>
                                    <input name="password2" type="password" class="form-control" placeholder="Repeat Password" v-model="password2"/>
                                </div>
                                <button class="btn btn-primary text-center mt-2" type="submit">
                                    <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                                    <span>Reset</span>
                                </button>
                                <!--<p class="text-center text-primary">Forgot your password?</p>-->
                                <div class="form-group pt-5">
                                  <div v-if="message" class="alert alert-danger" role="alert">
                                    {{ message }}
                                  </div>
                                </div>
                            </div>
                          </form>
                    </div>
                </div>
            </div>
        </div>
  </template>

  <script>
//import axios from 'axios';
import authService from '@/services/auth.service';

export default {
  name: "PasswordResetView",
  data() {

    return {
      logo: require('../../public/ambrosi-logo@2x.webp'),
      password1: "",
      password2: "",
      loading: false,
      message: "",
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.loggedIn;
    },
  },
  created() {
  },
  methods: {
    handleReset() {
      if (!this.password1 || !this.password2 || this.password1 != this.password2){
        this.loading = false;
        this.message = "Errore nelle password inserite!";
        return;
      }
      this.loading = true;
      authService.actualReset(this.$route.query.token,this.password1).then(() => {
        this.$router.replace({ path: '/login' })
      }).catch(() => {
        this.message = "Something went wrong. Please retry! :(";
      })
    },
  },
};
  </script>