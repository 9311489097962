<template>
  <NavBar />
  <router-view v-slot="{ Component }">
  <keep-alive include="AccettazioneHome,TableView,MagazzinoView,VenditoriHome">
    <component :is="Component" />
  </keep-alive>
</router-view>
  
</template>

<script>
  import NavBar from './components/NavBar.vue';
  //import Footer from './components/Footer.vue';

  
  export default {
  components: {
    NavBar//, Footer
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100%;
}

html {
  height: 100%
}
body {
    min-height: 100%;
    position: relative;
}
</style>
