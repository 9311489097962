<template>
    <div class="row border">
        <h4>Veicolo</h4>
        <div class="col-sm">
            
            <div class="row">
                    <div class="col fw-bolder text-end">ID gestionale:</div>
                    <div class="col">{{ veicolo.id_gestionale }}</div>
            </div>

            <div class="row">
                    <div class="col fw-bolder text-end">Marca:</div>
                    <div class="col">{{ veicolo.marca}}</div>
            </div>

            <div class="row">
                    <div class="col fw-bolder text-end">Km:</div>
                    <div class="col">{{ veicolo.km}}</div>
            </div>

            <div class="row">
                        <div class="col fw-bolder text-end">Data Ritiro:</div>
                        <div class="col">{{ veicolo.dataRitiro}}</div>
                </div>
        <div class="row">
                <div class="col fw-bolder text-end">Ubicazione:</div>
                <div class="col">{{ veicolo.ubicazione.descrizione}}</div>
        </div>

        </div>
        <div class="col-sm">

            <div class="row">
                    <div class="col fw-bolder text-end">Telaio:</div>
                    <div class="col">{{ veicolo.telaio}}</div>
            </div>

            <div class="row">
                    <div class="col fw-bolder text-end">Modello:</div>
                    <div class="col">{{ veicolo.modello}}</div>
            </div>

            <div class="row">
                    <div class="col fw-bolder text-end">Immatric.:</div>
                    <div class="col">{{ veicolo.dataImmatricolazione}}</div>
            </div>

            <div class="row">
                        <div class="col fw-bolder text-end">Valore Ritiro:</div>
                        <div class="col">{{ veicolo.valoreRitiro}}</div>
                </div>

        </div>
        <div class="col-sm">
        
            <div class="row">
                    <div class="col fw-bolder text-end">Targa:</div>
                    <div class="col">{{ veicolo.targa}}</div>
            </div>

            <div class="row">
                    <div class="col fw-bolder text-end">Colore:</div>
                    <div class="col">{{ veicolo.colore}}</div>
            </div>

            <div class="row">
                    <div class="col fw-bolder text-end">Data Contratto:</div>
                    <div class="col">{{ veicolo.dataContratto}}</div>
            </div>

            <div class="row">
                    <div class="col fw-bolder text-end">Data Uscita Stock:</div>
                    <div class="col">{{ veicolo.dataUscita}}</div>
            </div>

        </div>
    </div>
</template>

<script>
export default {
  name: 'VeicoloDettaglio',
  props: ['veicolo']
}
</script>