<template>
  <div class="container-fluid vh-100" style="margin-top:150px">
    <div class="d-flex justify-content-center"><img :src="logo" height="100"/></div>
            <div class="" style="margin-top:30px">
                <div class="rounded d-flex justify-content-center">
                    <div class="col-md-4 col-sm-12 shadow-lg p-5 bg-light">
                        <div class="text-center">
                            <h3 class="text-primary">Richiesta Reset Password</h3>
                        </div>
                        <form v-on:submit.prevent="handleLogin">
                            <div class="p-4">
                                <div class="input-group mb-3" v-if="!done">
                                    <span class="input-group-text bg-primary"><i
                                            class="bi bi-person-plus-fill text-white"></i></span>
                                    <input name="usernameAmbrosi" type="email" class="form-control" placeholder="E-Mail" v-model="email" required />
                                </div>
                                <button class="btn btn-primary text-center mt-2" type="submit" v-if="!done">
                                    <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                                    <span>Richiedi</span>
                                </button>
                                <!--<p class="text-center text-primary">Forgot your password?</p>-->
                                <div class="form-group pt-5">
                                  <div v-if="message" class="alert alert-danger" role="alert">
                                    {{ message }}
                                  </div>
                                </div>
                            </div>
                          </form>
                    </div>
                </div>
            </div>
        </div>
  </template>

  <script>
import authService from '@/services/auth.service';


export default {
  name: "RequestPasswordResetView",
  data() {

    return {
      logo: require('../../public/ambrosi-logo@2x.webp'),
      email: "",
      loading: false,
      done: false,
      message: "",
    };
  },
  computed: {
  
  },
  created() {
  },
  methods: {
    handleLogin() {
      this.loading = true;
      authService.requestReset(this.email).then(() => {
        this.loading = false;
        this.message = "Riceverai a breve una mail con le istruzioni per cambiare password!"
        this.done = true;
      });
    },
  },
};
  </script>