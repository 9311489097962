import axios from 'axios'
import authService from './auth.service';
const API_URL = process.env.VUE_APP_APIBASEURL;
class lavoriService {

    getUbicazioni(){
        return axios.get(API_URL + 'ubicazioni/', {headers : authService.authHeader()});
    }

    getLavorazioni(){
        return axios.get(API_URL + 'lavorazioni/', {headers : authService.authHeader()});
    }

    insertLavoro(lavoro){
        return axios.post(API_URL + 'lavori/', lavoro, {headers : authService.authHeader()});
    }

    deleteLavoro(id){
        return axios.delete(API_URL + 'lavori/' + id, {headers : authService.authHeader()});
    }

    updateLavoro(id, object){
        return axios.patch(API_URL + 'lavori/' + id, object, {headers : authService.authHeader()});
    }

    getLavori(params){
        return axios.get(API_URL + 'lavori/', {params, headers : authService.authHeader()});
    }

    getLavoriByRole(role){
        return axios.get(API_URL + 'lavori/byRoleId?role=' + role, {headers : authService.authHeader()});
    }

    magazzCode2Descr(code){
        switch (code){
            case "A": return "Arrivato";
            case "O": return "Ordinato";
            case "R": return "Richiesto";
        }
        return "";
    }

    convertToISO(date){return new Date(date).toISOString().split('T')[0]}
    today(){ return this.convertToISO(new Date()); }


}

export default new lavoriService();