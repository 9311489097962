import authService from "@/services/auth.service";

function initState(){
  const token =  localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("user"));
  if (token && userInfo){
    return {token, user: userInfo, loggedIn: true}
  }
  return {token: null, user: null, loggedIn: false}
}


export const auth = {
    namespaced: true,
    state: initState(),
    actions: {
      login({ commit }, user) {
        return authService.login(user).then(
          user => {
            commit('loginSuccess', user);
            return Promise.resolve(user);
          },
          error => {
            commit('loginFailure');
            return Promise.reject(error);
          }
        );
      },
      autologin({ commit }, token) {
        return authService.setToken(token).then(
          user => {
            commit('loginSuccess', {access_token: token, user_info: user});
            return Promise.resolve(user);
          },
          error => {
            commit('loginFailure');
            return Promise.reject(error);
          }
        );
      },
      logout({ commit }) {
        authService.logout();
        commit('logout');
      }
    },
    mutations: {
      loginSuccess(state, response) {
        state.loggedIn = true;
        state.token = response.access_token;
        state.user = response.user_info;
      },
      loginFailure(state) {
        state.loggedIn = false;
        state.token = null;
        state.user = null;
      },
      logout(state) {
        state.loggedIn = false;
        state.user = null;
        state.token = null;
      }
    }
  };