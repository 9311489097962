import axios from 'axios'
import authService from './auth.service';
const API_URL = process.env.VUE_APP_APIBASEURL;
class magazzinoService {

    getRichieste(){
        return axios.get(API_URL + 'magazzino/', {headers : authService.authHeader()});
    }

    getRichiesteByLavoroId(id_lavoro){
        return axios.get(API_URL + 'magazzino/'+ id_lavoro, {headers : authService.authHeader()});
    }

    insertRichiesta(id_lavoro, richiesta){
        return axios.post(API_URL + 'magazzino/', {
            lavoro_id: id_lavoro,
            richiesta: richiesta,
        }, {headers : authService.authHeader()});
    }

    updateRichiesta(id_lavoro, stato_){
        return axios.patch(API_URL + 'magazzino/'+id_lavoro, {
            stato: stato_,
        }, {headers : authService.authHeader()});
    }

    magazzCode2Descr(code){
        switch (code){
            case "A": return "Arrivato";
            case "O": return "Ordinato";
            case "R": return "Richiesto";
        }
        return "";
    }


}

export default new magazzinoService();