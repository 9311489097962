import { createRouter, createWebHistory } from 'vue-router'
import LoginView from '../views/LoginView.vue'
import TableView from '../views/TableView.vue'
import AccettazioneVeicolo from '../views/AccettazioneVeicolo.vue'
import ErrorView from '../views/ErrorView.vue'
import MagazzinoView from '../views/MagazzinoView.vue'
import AccettazioneHome from '../views/AccettazioneHome.vue'
import VenditoriHome from '../views/VenditoriHome.vue'
import StoricoHome from '../views/StoricoHome.vue'
import HomeView from '../views/HomeView.vue'
import Stock from '../views/Stock.vue'
import PasswordResetView from '../views/PasswordReset.vue'
import RequestPasswordResetView from '../views/RequestPasswordReset.vue'
import store from '@/store'

function role2Component(){
  if (store.state.auth.loggedIn)
  switch(store.state.auth.user.role.id) {
    case 1:
    case 2: //accettatore
      return AccettazioneHome;
    case 3: // lavaggista
    case 4: // foto
    case 5: // carrozziere
    case 6: // meccanico
      return TableView;
    case 7: // magazziniere
      return MagazzinoView;
    case 8: // venditore
      return VenditoriHome;
    case 9: // venditore usato
      return VenditoriHome;
    default:
      return HomeView;
  }

  return LoginView;
}
const routes = [
  {
    path: '/',
    name: 'home',
    component: role2Component(2)
  },
  {
    path: "/login",
    name: "Login",
    component: LoginView,
  },
  {
    path: "/storico",
    name: "Storico",
    component: StoricoHome,
  },
  {
    path: "/stock",
    name: "Stock",
    component: Stock,
  },
  {
    path: "/accettazione/:id",
    name: "Accettazione Veicolo",
    component: AccettazioneVeicolo,
  },
  {
    path: "/magazzino",
    name: "Magazzino",
    component: MagazzinoView,
  },
  {
    path: "/password/reset",
    name: "Password Reset",
    component: PasswordResetView,
  },
  {
    path: "/password/resetRequest",
    name: "Password Reset Request",
    component: RequestPasswordResetView,
  },
  {
    path: '/404',
    name: "Not Found Error",
    component: ErrorView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/login', '/password/reset', '/password/resetRequest'];
  const authRequired = !publicPages.includes(to.path);

  if (authRequired && !store.state.auth.loggedIn) {
      return '/login';
  }
});

/*const DEFAULT_TITLE = 'Ambrosi Usato';
router.afterEach(() => {
    Vue.nextTick(() => {
        document.title = /*to.meta.title || DEFAULT_TITLE;
    });
});*/

export default router
