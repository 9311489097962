<template>
    <div class="modal fade" v-bind:id="id" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h1 class="modal-title fs-5" id="staticBackdropLabel">Modifica nota</h1>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" v-bind:id="id+'closeButton'"></button>
                </div>
                
                <div class="modal-body" v-if="veicolo">
                    <div class="mb-3 bg-secondary text-white rounded p-3">
                        <span class="fw-bolder">ID gestionale</span>: {{ veicolo.id_gestionale }}<br>
                        <span class="fw-bolder">Telaio</span>: {{ veicolo.telaio }}<br>
                        <span class="fw-bolder">Targa</span>: {{ veicolo.targa }}<br>
                        <span class="fw-bolder">Marca</span>: {{ veicolo.marca }} <br>
                        <span class="fw-bolder">Modello</span>: {{ veicolo.modello }}<br>
                        </div>
                        <div class="mb-3">
                          <h4>Note</h4>
                          <p :contenteditable="true" @blur="onNoteChanged" placeholder="Aggiungi una nota QUI">{{ veicolo.note }}</p>
                        </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Chiudi</button>
                </div>
              </div>
            </div>
          </div>
    </template>
    
    <script>    
    import veicoliService from '@/services/veicoli.service';

    export default {
      name: 'NoteModal',
      props: ['id', 'veicolo'], //id used for modal idetnifier
      data(){
        return {
          
        }
      },
    
      methods: {
        onNoteChanged(event){
          if (this.veicolo.note != event.target.innerText)
            veicoliService.updateVeicolo(this.veicolo.id, {
              note: event.target.innerText
            }).then(() => {
              //console.log("Note saved!");
              this.$emit('success');
            });
        }
      }
    }
    </script>
    