<template>
<div class="container-lg mt-3">
  <h3>Accettazione veicolo <b>{{veicolo.targa}}</b> <span class="badge bg-danger mx-3 fs-7" v-if="veicolo.dataUscita"> Out of Stock </span></h3>
  <VeicoloDettaglio v-bind:veicolo="veicolo" v-if="!isFetching"/>
  <div class="row border mt-3">
    <h4>Note</h4>
    <p :contenteditable="canEdit" @blur="onNoteChanged" placeholder="Aggiungi una nota QUI">{{ veicolo.note }}</p>
  </div>
  <div class="row border mt-3">
    
    <div class="col-auto me-auto"><h4>Lavori</h4></div>
    <div class="col-auto float-end mt-2" v-if="canEdit && !this.$route.query.justNote">
      <div class="btn-group mx-2" role="group" aria-label="Basic example">
        <button class="btn btn-primary bi bi-plus-circle-fill" type="button" data-bs-toggle="modal" data-bs-target="#fastAddLavaggioFotoModal" :disabled="veicolo.dataUscita"> Lavaggio + Foto </button>
      </div>
      <div class="btn-group" role="group" aria-label="Basic example">
        <button class="btn btn-primary bi bi-plus-circle-fill" type="button" data-bs-toggle="modal" data-bs-target="#aggiungiModal" :disabled="veicolo.dataUscita"> Aggiungi </button>
      </div>
    </div>
    <LavoroModal id="aggiungiModal" :add="true" v-bind:veicolo_id="veicolo.id" @success="loadVeicolo()"/>
    <FastAddLavaggioFoto id="fastAddLavaggioFotoModal" title="Conferma" v-bind:veicolo_id="veicolo.id" @success="loadVeicolo()"/>

    <draggable
      tag="ul"
      :disabled="veicolo.dataUscita"
      :list="veicolo.lavori"
      class="list-group p-3"
      handle=".handle"
      item-key="id"
      @end="reordered()" 
      v-if="!isFetching"
    >

      <template #item="{ element }">
        <li class="list-group-item" v-bind:class="element.dataFine ? 'list-group-item-success' : (element.dataInizio ? 'list-group-item-warning' : '')" >
          <i class="fs-4 bi bi-justify handle float-start pe-3"></i>

          <span class="text fs-5">{{ element.lavorazione.descrizione }} [{{ element.dataInizio }} - {{ element.dataFine }}] <span v-if="element.waitMagazzino" class="badge bi bi-box-fill bg-warning text-dark "> Magazzino</span></span>
          <div class="btn-group float-end" role="group">
            <button type="button" class="btn btn-light btn-outline-secondary bi bi-eye" data-bs-toggle="modal" data-bs-target="#modificaModal" @click="selectLavoro(element)" title="Modifica"/>
            <button type="button" class="btn btn-light btn-outline-secondary bi bi-trash" v-if="!element.dataFine && canEdit" data-bs-toggle="modal" data-bs-target="#deleteModal" @click="selectLavoro(element)"  title="Rimuovi"/>
            <button type="button" class="btn btn-light btn-outline-secondary bi bi-check-circle" v-if="!element.dataFine && element.dataInizio && canEdit && ((role_id == 1) || ( role_id == 2 && [3].includes(element.lavorazione.id)))" data-bs-toggle="modal" data-bs-target="#terminaModal" @click="selectLavoro(element)" title="Termina"></button>
            <button type="button" class="btn btn-light btn-outline-secondary bi bi-box-arrow-up-right" v-if="!element.dataFine && !element.dataUscita && canEdit" data-bs-toggle="modal" data-bs-target="#esternaModal" @click="selectLavoro(element)" title="Esegui esternamente"></button>
          </div>
          <span class="badge bg-secondary bi bi-box-arrow-up-right mx-3 fs-7" v-if="element.esterno"> Esterno </span>
          <span class="badge bi bi-box-fill mx-3 fs-7" :class="magazColor(element)" v-if="[3,4].includes(element.lavorazione.id)" title="Stato richieste magazzino" >&nbsp; {{ element.magazCompleted }} di {{ element.magaz }}</span>

        </li>
      </template>
    </draggable>
    <LavoroModal id="modificaModal" :add="false" v-bind:item="selectedLavoro" v-bind:veicolo_id="veicolo.id"/>
    <TerminateModal id="terminaModal" v-bind:item="selectedLavoro" title="Termina lavoro " @success="loadVeicolo()"/>
    <DeleteModal id="deleteModal" v-bind:item="selectedLavoro" @success="loadVeicolo()" title="Elimina lavoro"/>
    <EsternaModal id="esternaModal" v-bind:item="selectedLavoro" title="Esegui lavoro esternamente" @success="loadVeicolo()"/>

  </div>
</div>
</template>

<script>
import veicoliService from '@/services/veicoli.service';
import VeicoloDettaglio from '@/components/VeicoloDettaglio.vue';
import FastAddLavaggioFoto from '@/components/FastAddLavaggioFoto.vue';
import LavoroModal from '@/components/LavoroModal.vue'
import TerminateModal from '@/components/TerminateModal.vue';
import EsternaModal from '@/components/EsternaModal.vue';
import DeleteModal from '@/components/DeleteModal.vue';
import draggable from "vuedraggable";
import lavoriService from '@/services/lavori.service';

export default {
  name: 'AccettazioneVeicolo',
  components: {
    VeicoloDettaglio, draggable, LavoroModal, TerminateModal, DeleteModal, EsternaModal, FastAddLavaggioFoto
  },
  data(){
    return {
        isFetching: true,
        veicolo: {},
        selectedLavoro: {}
    }
  },
  created(){
    this.loadVeicolo()
  },
  computed: {
    role_id(){
      return this.$store.state.auth.user.role.id;
    },

    canEdit(){
      return (this.role_id <=2); // 2 è il ruolo dell'accettatore
    }
  },
  methods: {
    loadVeicolo() {
      veicoliService.getVeicoloById(this.$route.params.id).then(response =>{
        this.veicolo = response.data;

        // ordina i lavori per priorita
        this.veicolo.lavori.sort((a, b) => (a.priority - b.priority));

        this.isFetching = false;
    }).catch(error => {
        if (error.response.status == 404)
            this.$router.push('/404');
    });
    },
    removeLavoro(id) {

      lavoriService.deleteLavoro(id).finally(() => {this.loadVeicolo();})
    },
    selectLavoro(lavoro){
      this.selectedLavoro = lavoro;
    },
    magazColor(m){
          if (m.magaz == 0) return 'bg-outline-secondary bg-secondary';
          return ((m.magazCompleted != m.magaz) ? 'bg-danger' : 'bg-success');
        },
    reordered(){
      let promises = [];
      for(let i = 0; i < this.veicolo.lavori.length; i++){
        let lavoro = this.veicolo.lavori[i];
        if (lavoro.priority != i)
          promises.push(lavoriService.updateLavoro(lavoro.id, {priority: i}));
      }

      Promise.all(promises).finally(() => {
        this.loadVeicolo();
      });
    },
    onNoteChanged(event){
      if (this.veicolo.note != event.target.innerText)
        veicoliService.updateVeicolo(this.veicolo.id, {
          note: event.target.innerText
        }).then(() => {
          console.log("Note saved!");
        });
    }
  }
}
</script>
<style>
[contenteditable=true]:empty:before{
  content: attr(placeholder);
  pointer-events: none;
  display: block; /* For Firefox */
}
</style>