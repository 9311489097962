<template>
  <div class="modal modal-lg fade" v-bind:id="id" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h1 class="modal-title fs-5" id="staticBackdropLabel">Dettaglio Officina Esterna</h1>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" v-bind:id="id+'closeBtn'"></button>
                </div>
                <div class="modal-body">
                        <div class="mb-3" v-if="ubicazione">
                          
                            <span class="fw-bolder">Ragione sociale</span>: {{ ubicazione.ragioneSociale }}<br>
                            <span class="fw-bolder">Indirizzo</span>: {{ ubicazione.indirizzo }}<br>
                            <span class="fw-bolder">Telefono</span>: <a :href="'tel:'+ubicazione.telefono">{{ ubicazione.telefono }}</a><br>
                            <span class="fw-bolder">Email</span>: <a :href="'mailto:'+ubicazione.email">{{ ubicazione.email }}</a><br>
                            <span class="fw-bolder">Riferimento</span>: {{ ubicazione.riferimento }}<br><br>
                        
                        </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Chiudi</button> 
                </div>
              </div>
            </div>
          </div>
</template>
<script>
export default {
  name: 'ViewFornitore',
  props: ['id', 'ubicazione'],
  data(){
    return {

    }
  },
  mounted(){
  },
  computed: {
  },

  methods: {
  }
}
</script>