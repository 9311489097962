<template>
<nav class="navbar navbar-expand-lg navbar-dark bg-dark">
  <div class="container-fluid">
    <a class="navbar-brand" href="#"><img :src="logo" height="25"/></a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <a class="nav-link" :class="this.$route.path == '/' ? 'active' : ''" aria-current="page" href="/">Home</a>
        </li>
        <li class="nav-item" v-if="(logged && (role_id <= 2))" >
          <a class="nav-link" :class="this.$route.path == '/stock' ? 'active' : ''" aria-current="page" href="/stock">Da rientrare</a>
        </li>
        <li class="nav-item" v-if="(logged && (role_id <= 2))">
          <a  class="nav-link" :class="this.$route.path == '/storico' ? 'active' : ''" aria-current="page" href="/storico">Storico</a>
        </li>
        <li class="nav-item" v-if="(logged && (role_id == 1))">
          <a  class="nav-link" :class="this.$route.path == '/magazzino' ? 'active' : ''" aria-current="page" href="/magazzino">Magazzino</a>
        </li>
      </ul>
      <ul class="d-flex navbar-nav">
        <li class="nav-item dropdown" v-if="logged">
          <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            <i class="bi bi-person-circle"></i> {{ name }} <span style="font-size: 12px;">({{ role }})</span>
          </a>
          <ul class="dropdown-menu dropdown-menu-dark">
            <li><a class="dropdown-item" href="#" @click="logout" ><i class="bi bi-door-open-fill"></i> Logout</a></li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</nav>
</template>

<script>

export default {
  data() {
    return {
      logo: require('../../public/ambrosi-logo@2x.webp')
    }
  }, 
  methods: {
    logout(){
      this.$store.dispatch("auth/logout");
      this.$router.push('/login');
    },
    profile(){
      this.$router.push('/me');
    }
  }, 
  computed : {
    name(){
      return this.$store.state.auth.user.name;
    },
    logged(){
      return this.$store.state.auth.loggedIn;
    },
    role(){
      return this.$store.state.auth.user.role.name;
    },
    role_id(){
      return this.$store.state.auth.user.role.id;
    },
  }
}
</script>