<template>
    <div>
      <h1>Venditori</h1>
      <div class="d-flex flex-row-reverse">
        <div class="p-2"><input type="text" v-model="searchValue" placeholder="Search Value"></div>
        <div class="vr my-2"></div>
        <div class="p-2">
            <div class="btn-group btn-group-sm" role="group">
                <input type="radio" class="btn-check" name="btnradio" id="btnradio0" autocomplete="off" checked> 
                <label class="btn btn-outline-primary" for="btnradio0" @click="addFilter()">Tutti <span class="badge bg-secondary">{{ items.length }}</span></label>

                <input type="radio" class="btn-check" name="btnradio" id="btnradio1" autocomplete="off">
                <label class="btn btn-outline-primary" for="btnradio1" @click="addFilter('D')">Da Gestire  <span class="badge bg-secondary">{{ countDaAccettare() }}</span></label>

                
                <template v-for="lavorazione in lavorazioni" :key="lavorazione.id">
                  <input  type="radio" class="btn-check" name="btnradio" :id="'btn'+lavorazione.id" autocomplete="off">
                  <label  class="btn btn-outline-primary" :for="'btn'+lavorazione.id"  @click="addFilter(lavorazione.id)">{{lavorazione.descrizione}} <span class="badge bg-secondary">{{ countInLavorazione(lavorazione.id) }}</span></label>
                </template>
                
                <input type="radio" class="btn-check" name="btnradio" id="btnradio3" autocomplete="off">
                <label class="btn btn-outline-primary" for="btnradio3" @click="addFilter('C')">Completata <span class="badge bg-secondary">{{ countComplete() }}</span></label>
                </div>
        </div>
      </div>
      <div></div>
      <DataTable 
        :headers="headers" 
        :items="items"
        :search-value="searchValue"
        :search-field="searchFields"
        :loading="loading_"
        :body-row-class-name="bodyRowClassNameFunction"
        :filter-options="currentFilters"
        :empty-message="'Nessuna veicolo presente'"
        no-hover
      >
        <template #loading>
            <img src="https://i.pinimg.com/originals/94/fd/2b/94fd2bf50097ade743220761f41693d5.gif" style="width: 100px; height: 80px;" />
        </template>
        <template #header-ubicazione="header">
      <div class="filter-column">
        <i class="bi bi-filter" @click.stop="showUbicazioneFilter=!showUbicazioneFilter"></i>
        {{ header.text }}
        <div
          class="filter-menu filter-sport-menu"
          v-if="showUbicazioneFilter"
        >
          <select
            class="ubicazione-selector"
            v-model="ubicazioneCriteria"
            name="unicazioneS"
            @change="showUbicazioneFilter=false"
          >
          <option value="all">Tutte</option>
          <option v-for="ubi in getUbicazioni()"  :key="ubi" :value="ubi">{{ubi}}</option>
          <option value="">&#60;vuoto&#62;</option>
          </select>
        </div>
      </div>
    </template>
    <template #header-marca="header">
      <div class="filter-column">
        <i class="bi bi-filter" @click.stop="showMarcaFilter=!showMarcaFilter"></i>
        {{ header.text }}
        <div
          class="filter-menu filter-sport-menu"
          v-if="showMarcaFilter"
        >
          <select
            class="ubicazione-selector"
            v-model="marcaCriteria"
            name="unicazioneS"
            @change="showMarcaFilter=false"
          >
          <option value="all">Tutte</option>
          <option v-for="marca in getMarche()"  :key="marca" :value="marca">{{marca}}</option>
           <option value="">&#60;vuoto&#62;</option>
          </select>
        </div>
      </div>
    </template>
    <template #header-area="header">
    <div class="filter-column">
        <i class="bi bi-filter" @click.stop="showAreaFilter=!showAreaFilter"></i>
        {{ header.text }}
        <div
          class="filter-menu filter-sport-menu"
          v-if="showAreaFilter"
          
        >
          <select
            class="ubicazione-selector"
            v-model="areaCriteria"
            name="areaFilter"
            @change="showAreaFilter=false"
          >
          <option value="all">Tutte</option>
          <option v-for="area in aree"  :key="area.id" :value="area.id">{{area.descrizione}}</option>
          <option value="">&#60;vuoto&#62;</option>
          </select>
        </div>
      </div>
    </template>
        <template #header-prezzo="header">
          <div class="filter-column">
            <i class="bi bi-filter" @click.stop="showAgeFilter=!showAgeFilter"></i>
            {{ header.text }}
            <div class="filter-menu filter-age-menu" v-if="showAgeFilter" @click.stop="showAgeFilter=!showAgeFilter">
              <Slider :merge="15000" v-model="priceCriteria" class="slider" :min="0" :max="150000" :format="{suffix: '€'}" :step="1000"/>
            </div>
          </div>
        </template>
        <template #header-dataImmatricolazione="header">
          <div class="filter-column">
            <i class="bi bi-filter" @click.stop="showImmaFilter=!showImmaFilter"></i>
            {{ header.text }}
            <div class="filter-menu filter-age-menu" v-if="showImmaFilter" @click.stop="showImmaFilter=!showImmaFilter">
              <Slider v-model="immaCriteria" class="slider" :min="2000" :max="new Date().getFullYear()" :step="1"/>
            </div>
          </div>
        </template>
        <template #header-km="header">
          <div class="filter-column">
            <i class="bi bi-filter" @click.stop="showKmFilter=!showKmFilter"></i>
            {{ header.text }}
            <div class="filter-menu filter-age-menu" v-if="showKmFilter" @click.stop="showKmFilter=!showKmFilter">
              <Slider :merge="50000" v-model="kmCriteria" class="slider" :min="0" :max="500000" :format="{suffix: 'KM'}" :step="10000"/>
            </div>
          </div>
        </template>
        <template #header-riserva="header">
      <div class="filter-column">
        <i class="bi bi-filter" @click.stop="showRiservaFilter=!showRiservaFilter"></i>
        {{ header.text }}
        <div
          class="filter-menu filter-sport-menu"
          v-if="showRiservaFilter"
        >
          <select
            class="ubicazione-selector"
            v-model="riservaCriteria"
            name="unicazioneS"
            @change="showRiservaFilter=false"
          >
          <option value="a">Tutte</option>
          <option value="r">Riservate</option>
          <option value="f">Libere</option>
          </select>
        </div>
      </div>
    </template>
    <template #item-prezzo="item">
          <span style="float: right;">{{ item.prezzo.toLocaleString(undefined, {minimumFractionDigits: 2}) }}</span>
        </template>
        <template #item-iva="item">
          <i class="bi" :class="item.iva ? 'bi-check-circle' : 'bi-circle'"></i>
        </template>
        <template #item-riserva="item">
          <button type="button" class="w-100 btn btn-secondary bi bi-lock-fill btn-sm" data-bs-toggle="modal" data-bs-target="#bookM" @click="select(item)" v-if="!item.booked" title="Riserva">Riserva</button>
          <button type="button" class="w-100 btn btn-success bi bi-unlock-fill btn-sm" data-bs-toggle="modal" data-bs-target="#unbookM" @click="select(item)" v-if="item.booked && (item.riservaUser == userId())" title="Rilascia">Rilascia</button>
          <button type="button" class="w-100 btn btn-danger bi bi-lock-fill btn-sm" v-if="item.booked && (item.riservaUser != userId())" :title="(item.riservaUserN + ' (' + item.riservaData + ')')">{{item.riservaUserN.split(" ").map((n)=>n[0]).join(".")}}</button>
        </template>
        <template #item-operation="item">
            <div class="btn-group btn-sm">
                <button type="button" class="btn btn-primary bi bi-eye btn-sm" @click="$router.push('/accettazione/'+item.id)" />
                <button type="button" class="btn btn-info bi bi-bookmark-fill btn-sm" data-bs-toggle="modal" data-bs-target="#noteM" @click="select(item)" v-if="(role_id == 8)"/>
                <button type="button" class="btn btn-primary bi bi-pencil-fill btn-sm" data-bs-toggle="modal" data-bs-target="#dealerM" @click="select(item)" v-if="(role_id == 8)" />
              </div>
        </template>
         <template #item-stato="item">
            {{ computeStato(item) }}
        </template>
        <template #item-area="item">
            {{ computeArea(item.id_area) }}
        </template>
        <template #item-gis="item">
          <span :title="item.dataRitiro">{{ item.gis }}</span>
        </template>
        <template #item-dataPrevistoRientro="item">
          <span v-if="item.ubiesterno">{{ item.ubiesterno }} ({{ item.dataPrevistoRientro }})</span>
        </template>
     
      </DataTable>
      <NoteModal :veicolo="selectedItem" id="noteM" @success="fetchData()"></NoteModal>
      <BookModal :veicolo="selectedItem" id="bookM" @success="fetchData()"></BookModal>
      <BookModal :veicolo="selectedItem" id="unbookM" :unbook="true" @success="fetchData()"></BookModal>
      <DealersNotesModal :veicolo="selectedItem" :aree="aree" id="dealerM" @success="fetchData()"></DealersNotesModal>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import lavoriService from '@/services/lavori.service';
  import NoteModal from '@/components/NoteModal.vue';
  import veicoliService from '@/services/veicoli.service';
  import BookModal from '@/components/BookVeicolo.vue';
  import DealersNotesModal from '@/components/DealerNotesModal.vue'
  import Slider from "@vueform/slider";
  import "@vueform/slider/themes/default.css";
  export default {

    name: 'VenditoriHome',
    components: {
        NoteModal, Slider, BookModal, DealersNotesModal
    },
    created(){
     
    },
    data() {
        return {
        loading_:true,
        searchValue: "",
        headers: [
          { text: "ID gestionale", value: "id_gestionale" },
          { text: "Ubicazione", value: "ubicazione"},
          { text: "Area", value: "area"},
          //{ text: "Telaio", value: "telaio", sortable: true},
          { text: "Targa", value: "targa", sortable: true},
          { text: "Marca", value: "marca", sortable: true},
          { text: "Modello", value: "modello", width: 150, sortable: true},
          //{ text: "Valore", value: "valoreRitiro", sortable: true},
          { text: "Colore", value: "colore", sortable: true},
          { text: "KM", value:"km", sortable: true},
          //{ text: "Prevista Consegna", value: "dataPrevistaConsegna", sortable: true},
          { text: "Data Immatricolazione", value: "dataImmatricolazione", width:100, sortable: true},
          { text: "Prezzo", value: "prezzo", sortable: true},
          { text: "IVA", value: "iva"},
          { text: "Codice Chiave", value: "codiceChiave"},
          { text: "Data Contratto", value: "dataContratto", sortable: true},
          { text: "Note", value: "note", width: 150, sortable: true},
          { text: "Stato", value: "stato"},
          { text: "Riserva", value: "riserva", width:104},
          { text: "", value: "operation"}
        ],
        items: [],

        searchFields: ["id_gestionale", "telaio", "targa", "marca", "modello", "colore"],

        selectedItem: null,

        lavorazioni: [],

        aree: [],

        today: new Date().getTime(),

      
        showAgeFilter: false,
        showKmFilter: false,
        showUbicazioneFilter: false,
        showRiservaFilter: false,
        showAreaFilter: false,
        showMarcaFilter: false,
        showImmaFilter: false,

        priceCriteria: [0, 1000000],
        kmCriteria: [0, 500000],
        ubicazioneCriteria: "all",
        areaCriteria: "all",
        marcaCriteria: "all",
        riservaCriteria: "a",
        immaCriteria: [2000, new Date().getFullYear()],
        filterOptions: []
        }
    },
    mounted(){
        if (this.role_id == 9) {
          this.headers.splice(2,1);
          this.headers.splice(10,1);
        }
        this.fetchData();
        this.filterOptions.push();
    },
    computed : {
      currentFilters(){
        let filters = (this.ubicazioneCriteria == "all" ? this.filterOptions : this.filterOptions.concat({ field: 'ubicazione', comparison: '=', criteria: this.ubicazioneCriteria}));
        if (this.areaCriteria != "all") filters = filters.concat({ field: 'id_area', comparison: '=', criteria: this.areaCriteria});
        if (this.marcaCriteria != "all") filters = filters.concat({ field: 'marca', comparison: '=', criteria: this.marcaCriteria});

        return filters.concat({
            field: 'km',
            comparison: 'between',
            criteria: this.kmCriteria
          },{
            field: 'booked',
            comparison: ((value, criteria) => {
                if (criteria == "a") return true;
                if (criteria == "r" && value) return true;
                if (criteria == "f" && !value) return true;
                return false;
            }),
            criteria: this.riservaCriteria
          },{
            field: 'prezzo',
            comparison: 'between',
            criteria: this.priceCriteria
          },{
            field: 'dataImmatricolazione',
            comparison: ((value, criteria) => {
                let y = new Date(value).getFullYear();
                return (y >= criteria[0] && y <= criteria[1]);
            }),
            criteria: this.immaCriteria
          });
      },
      role_id(){
        return this.$store.state.auth.user.role.id;
    },
    }, 
    methods : {
      userId(){
        return this.$store.state.auth.user.id;
      },
        fetchData(){ 

          lavoriService.getLavorazioni().then(response => {
              this.lavorazioni = response.data;
              return veicoliService.getAree();
            }).then(response =>{
              this.aree = response.data;
              return veicoliService.getVeicoliVenditori();
            }).then(response => {
            this.items = response.data;
            this.items.forEach(it => {
              it.booked = this.difference(it.riservaData) < 5;
              if (!it.prezzo) it.prezzo = 0;
              it.gis = this.difference(it.dataRitiro);
              if (!it.dataContratto) it.dataContratto = "";
              if (!it.dataPrevistoRientro) it.dataPrevistoRientro = "2030";
            });
            this.loading_ = false;
            
            });

            

        },

        magaz2Desc(code){
            return lavoriService.magazzCode2Descr(code);
        },

        getUbicazioni(){
          let unique = this.items.reduce(function (acc, curr) { 
              if (!acc.includes(curr.ubicazione)) 
                  acc.push(curr.ubicazione); 
              return acc; 
          }, []); 
          return unique; 
        },

        getMarche(){
          let unique = this.items.reduce(function (acc, curr) { 
              if (!acc.includes(curr.marca)) 
                  acc.push(curr.marca); 
              return acc; 
          }, []); 
          return unique; 
        },

        select(item){
          this.selectedItem = item;
        },

        removeFromList(i){
          this.items.splice(this.items.indexOf(i), 1);
        },

        bodyRowClassNameFunction(item){
            if (item.accettata) {
              if (item.lavorazione_id) return 'accettato-row';
              return 'completato-row';
            }
            return 'notAccettato-row';
        },
        computeStato(item){
            if (item.accettata) {
              if (item.lavorazione_id) {
                let ris = this.lavorazioni.find((e) => e.id == item.lavorazione_id);
                if (ris) return ris.descrizione;
                console.log(item.lavorazione_id);
                console.log(this.lavorazioni.find((e) => e.id == 4).descrizione);
              }
              return 'Completa';
            }
            return 'Da gestire';
        },

        computeArea(id_area){
          let area = this.aree.find(i => i.id == id_area);
          if (area == null) return "";
          return area.descrizione;
        },

        salva(){
            lavoriService.updateLavoro(this.selectedItem.id, {
                magazzino: this.selectedItem.magazzino
            }).then(() => {
                document.getElementById('closeBtn').click();
                this.loading_ = true;
                this.fetchData();
            })
        },

        difference(date) {
          var day = new Date(date);
          return Math.ceil((this.today - day.getTime())/ (1000 * 60 * 60 * 24));
        },

        countInLavorazione(id_lavorazione){
          return this.items.reduce((i,el) => {if (el.lavorazione_id == id_lavorazione) return ++i; return i;},0);
        },

        countComplete(){
          return this.items.reduce((i,el) => {if (el.lavorazione_id == null && el.accettata) return ++i; return i;},0);
        },

        countDaAccettare(){
          return this.items.reduce((i,el) => {if (!el.accettata) return ++i; return i;},0);
        },

        addFilter(filtro){
          this.headers[8].width = 1;
          this.filterOptions = [];
          if (!isNaN(filtro)){
            this.filterOptions.push({
              field: 'lavorazione_id',
              comparison: '=',
              criteria: filtro
            });
            this.headers[8].width = 30;
          }
          if (filtro == "C")
            this.filterOptions.push({
              field: 'lavorazione_id',
              comparison: '=', //(value, criteria) => {console.log(value + " -- " + criteria); console.log(typeof(value)); return value == "";},
              criteria: ""
            }, {
              field: 'accettata',
              comparison: '=',
              criteria: true
            });
          
          if (filtro == "D"){
            this.filterOptions.push({
              field: 'accettata',
              comparison: '=',
              criteria: false
            });
          }

          if (filtro == "G"){
            this.filterOptions.push({
              field: 'completata',
              comparison: '=',
              criteria: false
            }, {
              field: 'accettata',
              comparison: '=',
              criteria: true
            });
          }
        }
    }
  }
  </script>
  <style>
  .filter-column {
    display: flex;
    align-items: center;
    justify-items: center;
    position: relative;
  }
  .filter-icon {
    cursor: pointer;
    display: inline-block;
    width: 15px !important;
    height: 15px !important;
    margin-right: 4px;
  }
  .filter-menu {
    padding: 15px 30px;
    z-index: 1;
    position: absolute;
    top: 30px;
    width: 750px !important;
    background-color: #fff;
    border: 1px solid #e0e0e0;
  }
  .filter-age-menu {
    height: 80px;
  }
  .slider {
    margin-top: 36px;
    width: 700px !important;
  }
  .ubicazione-selector {
  width: 100%;
}
  </style>
  <style>

.notAccettato-row  {
  --easy-table-body-row-background-color: #f8d7da;;
  --easy-table-body-row-font-color: #842029;
}

.accettato-row  {
  --easy-table-body-row-background-color: #fff3cd;;
  --easy-table-body-row-font-color: #664d03;;
}
.completato-row  {
  --easy-table-body-row-background-color: #d1e7dd;
  --easy-table-body-row-font-color: #0f5132;;
}
</style>