<template>
  <div class="modal modal-lg fade" v-bind:id="id" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h1 class="modal-title fs-5" id="staticBackdropLabel" v-if="add"> Aggiungi Lavoro</h1>
                  <h1 class="modal-title fs-5" id="staticBackdropLabel" v-else> Modifica Lavoro</h1>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" v-bind:id="id+'closeBtn'"></button>
                </div>
                <form @submit.prevent="save">
                <div class="modal-body">
                  <div class="mb-3">
                        <label for="lavorazione" class="form-label">Lavorazione</label>
                        <select class="form-select" aria-label="Default select example" name="lavorazione" :disabled="!add" v-model="lavoro.lavorazione.id">
                            <option v-for="lavorazione in lavorazioni" :value="lavorazione.id" :key="lavorazione.id">
                                {{ lavorazione.descrizione }}
                            </option>
                        </select>
                    </div>
                        <div class="mb-3">
                            <label for="descrizione" class="form-label">Descrizione</label>
                            <textarea class="form-control" v-model="lavoro.descrizione" :disabled="lavoro.dataFine"></textarea>
                        </div>
                        <div class="mb-3" v-if="lavoro.numeroCommessa">
                          <span class="form-label"><h5>Commessa</h5></span>
                          <div class="mb-3 rounded p-3 bg-light">
                            <span class="fw-bolder">Numero commessa</span>: {{ lavoro.numeroCommessa }}<br>
                            <span class="fw-bolder">Data documento</span>: {{ lavoro.dataDoc }}<br>
                            <span class="fw-bolder">Numero documento</span>: {{ lavoro.numeroDoc }}
                          </div>
                        </div>
                        <div class="mb-3" v-if="lavoro.ubicazione">
                          <span class="form-label"><h5>Lavoro esterno presso</h5></span>
                          <div class="mb-3 rounded p-3 bg-light">
                            <span class="fw-bolder">Ragione sociale</span>: {{ lavoro.ubicazione.ragioneSociale }}<br>
                            <span class="fw-bolder">Indirizzo</span>: {{ lavoro.ubicazione.indirizzo }}<br>
                            <span class="fw-bolder">Telefono</span>: <a :href="'tel:'+lavoro.ubicazione.telefono">{{ lavoro.ubicazione.telefono }}</a><br>
                            <span class="fw-bolder">Email</span>: <a :href="'mailto:'+lavoro.ubicazione.email">{{ lavoro.ubicazione.email }}</a><br>
                            <span class="fw-bolder">Riferimento</span>: {{ lavoro.ubicazione.riferimento }}<br><br>

                            
                        <label for="previstoRientro" class="form-label fw-bolder">Data previsto rientro:</label>
                        <input type="date" class="form-control" name="previstoRientro" v-model="lavoro.dataPrevistoRientro" required>
                          </div>
                        </div>
                       <div v-if="showMagazzino">

                        <div class="mb-3" v-if="!add">
                            <span class="form-label"><h5>Richieste magazzino ({{ richieste.length }})</h5></span>
                            
                            <div class="mb-3 rounded p-3 bg-opacity-25" :class="richiesta2Color(richiesta.stato)" v-for="richiesta in richieste" v-bind:key="richiesta.id">
                                <span class="fw-bolder">Data richiesta</span>: {{ richiesta.dataRichiesta }}<br>
                                <span class="fw-bolder">Stato</span>: {{ code2Descr(richiesta.stato) }} <br>
                                <span class="fw-bolder">Richiedente</span>: {{ richiesta.richiedente.name }}<br>
                                <span v-if="richiesta.magazziniere"><span class="fw-bolder">Magazziniere</span>: {{ richiesta.magazziniere.name }}  <a class="bi bi-envelope-at" v-bind:href="'mailto:'+richiesta.magazziniere.email"></a><br></span>
                                <span v-if="richiesta.dataEvasione"><span class="fw-bolder">Data evasione</span>: {{ richiesta.dataEvasione }}<br></span>
                                <span class="fw-bolder">Descrizione</span>: {{ richiesta.richiesta }} <br>
                            </div>
                            <div v-if="!richieste || richieste.length == 0"> Nessuna richiesta di magazzino presente</div>

                        </div>
                        <div class="mb-3" v-if="!lavoro.dataFine && canEdit">
                            <label for="nuovaRichiesta" class="form-label">Richiesta magazzino</label>
                            <textarea class="form-control" v-model="nuovaRichiesta"></textarea>
                        </div>
                        <div class="mb-3" v-if="!add && !lavoro.dataFine && canEdit">
                            <button type="button" class="btn btn-primary" @click="saveRichiesta">Aggiungi richiesta</button>
                        </div>


                       </div>

                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Chiudi</button>
                  <button type="submit" class="btn btn-primary" :disabled="!canEdit">Salva</button>
                 
                </div>
            </form>
              </div>
            </div>
          </div>
</template>
<script>
import lavoriService from '@/services/lavori.service';
import magazzinoService from '@/services/magazzino.service';

export default {
  name: 'LavoroModal',
  props: ['id', 'add', 'item', 'veicolo_id'],
  data(){
    return {
        richieste: [],
        lavorazioni: [],
        lavoro: {
          lavorazione: {}
        }, 
        nuovaRichiesta: null
    }
  },
  watch: {
    item(newItem){
      this.lavoro = newItem;
      this.reloadRichieste()
      }
  },
  mounted(){

    lavoriService.getLavorazioni().then(response => {
      this.lavorazioni = response.data;
    }).catch(() => {
      console.log("Errore nel caricare le lavorazioni!");
    })
  },
  computed: {
    showMagazzino(){
      const lavoraz = this.lavorazioni.find( el => this.lavoro.lavorazione.id == el.id);
      if (lavoraz) return lavoraz.magazzino;
      return false;
    },
    role_id(){
      return this.$store.state.auth.user.role.id;
    },

    canEdit(){
      return (this.role_id <=2); // 2 è il ruolo dell'accettatore
    }

  },

  methods: {
    save(){
        if (this.add){
          // add
          lavoriService.insertLavoro({
            veicolo_id: this.veicolo_id,
            lavorazione_id: this.lavoro.lavorazione.id,
            descrizione: this.lavoro.descrizione,
            richiestaMagazzino: this.nuovaRichiesta
          }).then(() => {
            document.getElementById(this.id + 'closeBtn').click();
            this.lavoro = {lavorazione:{}};
            this.nuovaRichiesta = null;
            this.$emit('success');
          }).catch(error => {
              console.log(error);
          });

        } else {
          // update
          lavoriService.updateLavoro(this.lavoro.id, {
            descrizione: this.lavoro.descrizione,
            dataPrevistoRientro: this.lavoro.dataPrevistoRientro
          }).then(() => {
            console.log("UPDATE Successful");
            document.getElementById(this.id + 'closeBtn').click();
            this.$emit('success');
          }).catch(error => {
              console.log(error);
          });
        }
    },
    reloadRichieste(){
      magazzinoService.getRichiesteByLavoroId(this.lavoro.id).then(response => {
            this.richieste = response.data;
        });
    },
    code2Descr(code){
      return magazzinoService.magazzCode2Descr(code);
    },
    saveRichiesta(){
      magazzinoService.insertRichiesta(this.lavoro.id, this.nuovaRichiesta).then(() => {
        this.nuovaRichiesta = null;
        this.reloadRichieste();
      })
    },
    richiesta2Color(stato){
      switch(stato){
        case "R": return "bg-danger";
        case "O": return "bg-warning";
        case "A": return "bg-success";
      }
    },
    todayString(){
            var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth()+1; //January is 0!
        var yyyy = today.getFullYear();
        if(dd<10){
                dd='0'+dd
            } 
            if(mm<10){
                mm='0'+mm
            } 

        return yyyy+'-'+mm+'-'+dd;
        }
  }
}
</script>