import axios from 'axios'
import authService from './auth.service';

const API_URL = process.env.VUE_APP_APIBASEURL;
class veicoliService {

    getVeicoli(){
        return axios.get(API_URL + 'veicoli/t', {headers : authService.authHeader()});
    }

    getVeicoliVenditori(){
        return axios.get(API_URL + 'veicoli/venditori', {headers : authService.authHeader()});
    }

    getAllStock(){
        return axios.get(API_URL + 'veicoli/stock', {headers : authService.authHeader()});
    }

    getStorico(){
        return axios.get(API_URL + 'veicoli/storico', {headers : authService.authHeader()});
    }

    getSedi(){
        return axios.get(API_URL + 'sedi/', {headers : authService.authHeader()});
    }

    getAree(){
        return axios.get(API_URL + 'aree/', {headers : authService.authHeader()});
    }

    getVeicoloById(id){
        return axios.get(API_URL + 'veicoli/' + id, {headers : authService.authHeader()});
    }

    updateVeicolo(id, obj){
        return axios.patch(API_URL + 'veicoli/' + id, obj, {headers : authService.authHeader()});
    }

    bookVeicolo(id){
        return axios.post(API_URL + 'veicoli/book/' + id, {}, {headers : authService.authHeader()});
    }

    unbookVeicolo(id){
        return axios.post(API_URL + 'veicoli/unbook/' + id, {}, {headers : authService.authHeader()});
    }


}

export default new veicoliService();