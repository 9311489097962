<template>
    <div class="modal modal-lg fade" id="magazzinoModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h1 class="modal-title fs-5" id="staticBackdropLabel">{{title}}</h1>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <form>
                <div class="modal-body">
                    <div class="mb-3 bg-secondary text-white rounded p-3" v-if="item">
                        <span class="fw-bolder">ID gestionale</span>: {{ item.veicolo.id_gestionale }}<br>
                        <span class="fw-bolder">Telaio</span>: {{ item.veicolo.telaio }}<br>
                        <span class="fw-bolder">Targa</span>: {{ item.veicolo.targa }}<br>
                        <span class="fw-bolder">Marca</span>: {{ item.veicolo.marca }} <br>
                        <span class="fw-bolder">Modello</span>: {{ item.veicolo.modello }}<br>
                        </div>
                        <div class="mb-3">
                            <span class="form-label">Richieste ({{ richieste.length }})</span>
                            
                            <div class="mb-3 rounded p-3 bg-opacity-25" :class="richiesta2Color(richiesta.stato)" v-for="richiesta in richieste" v-bind:key="richiesta.id">
                                <span class="fw-bolder">Data richiesta</span>: {{ richiesta.dataRichiesta }}<br>
                                <span class="fw-bolder">Stato</span>: {{ code2Descr(richiesta.stato) }} <br>
                                <span class="fw-bolder">Richiedente</span>: {{ richiesta.richiedente.name }}<br>
                                <span v-if="richiesta.magazziniere"><span class="fw-bolder">Magazziniere</span>: {{ richiesta.magazziniere.name }}  <a class="bi bi-envelope-at" v-bind:href="'mailto:'+richiesta.magazziniere.email"></a><br></span>
                                <span v-if="richiesta.dataEvasione" class="fw-bolder">Data evasione</span>: {{ richiesta.dataEvasione }}<br>
                                <span class="fw-bolder">Descrizione</span>: {{ richiesta.richiesta }} <br>
                            </div>
                            <div v-if="!richieste || richieste.length == 0"> Nessuna richiesta di magazzino presente</div>

                        </div>
                        <div class="mb-3">
                            <label for="nuovaRichiesta" class="form-label">Nuova richiesta</label>
                            <textarea class="form-control" v-model="nuovaRichiesta" required></textarea>
                        </div>
                        <div class="mb-3">
                            <button type="button" class="btn btn-primary" @click="save">Aggiungi richiesta</button>
                        </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Chiudi</button>
                </div>
            </form>
              </div>
            </div>
          </div>
    </template>
    
    <script>
    import magazzinoService from '@/services/magazzino.service';
    
    export default {
      name: 'RichiesteMagazzinoModal',
      props: ['item', 'title'],
      data(){
        return {
            richieste: [],
            nuovaRichiesta: null,
        }
      },
    
      methods: {
        loadRichieste(itm){
          magazzinoService.getRichiesteByLavoroId(itm.id).then(response => {
            this.richieste = response.data;
        });
        },
        code2Descr(i){return magazzinoService.magazzCode2Descr(i)},

        save(){
            if (this.nuovaRichiesta)
                magazzinoService.insertRichiesta(this.item.id, this.nuovaRichiesta).then(() =>{
                    this.nuovaRichiesta = null;
                    this.loadRichieste(this.item);
                    this.$emit('success');
                });
        },
        richiesta2Color(stato){
      switch(stato){
        case "R": return "bg-danger";
        case "O": return "bg-warning";
        case "A": return "bg-success";
      }
    }
      }
    }
    </script>
    