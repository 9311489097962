import {createStore} from 'vuex';
import {auth} from './auth.module'
import { sedi } from './sedi.module';

const store = createStore({
    modules : {
        auth, sedi
    },
});

export default store;