import axios from 'axios'
import authService from './auth.service';
const API_URL = process.env.VUE_APP_APIBASEURL;
class ubicazioniService {

    insertUbicazione(ubicazObj){
        return axios.post(API_URL + 'ubicazioni/', ubicazObj, {headers : authService.authHeader()});
    }

   

}

export default new ubicazioniService();