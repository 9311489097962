<template>
    <div>
      <h1>Magazzino</h1>
      <div class="d-flex flex-row-reverse">
        <div class="p-2"><input type="text" v-model="searchValue" placeholder="Search Value"></div>
        <div class="vr my-2"></div>
        <div class="p-2">
            <div class="btn-group btn-group-sm" role="group" aria-label="Basic radio toggle button group">
                <input type="radio" class="btn-check" name="btnradio" id="btnradio0" autocomplete="off" checked> 
                <label class="btn btn-outline-primary" for="btnradio0" @click="addFilter()">Tutti</label>

                <input type="radio" class="btn-check" name="btnradio" id="btnradio1" autocomplete="off">
                <label class="btn btn-outline-primary" for="btnradio1" @click="addFilter('R')">Richiesto</label>

                <input type="radio" class="btn-check" name="btnradio" id="btnradio2" autocomplete="off">
                <label class="btn btn-outline-primary" for="btnradio2" @click="addFilter('O')">Ordinato</label>

                <input type="radio" class="btn-check" name="btnradio" id="btnradio3" autocomplete="off">
                <label class="btn btn-outline-primary" for="btnradio3" @click="addFilter('A')">Arrivato</label>
                </div>
        </div>
      </div>
      <div></div>
      <DataTable 
        :headers="headers" 
        :items="items"
        :search-value="searchValue"
        :search-field="searchFields"
        :loading="loading_"
        :body-row-class-name="bodyRowClassNameFunction"
        :filter-options="filterOptions"
        :empty-message="'Nessuna richiesta presente'"
        no-hover
      >
        <template #loading>
            <img src="https://i.pinimg.com/originals/94/fd/2b/94fd2bf50097ade743220761f41693d5.gif" style="width: 100px; height: 80px;" />
        </template>
        <template #item-esterno="item">
          <button type="button" class="btn btn-info bi bi-box-arrow-up-right btn-sm" data-bs-toggle="modal" data-bs-target="#viewFornitore" @click="select(item)" v-if="item.lavoro.ubicazione" />
        </template>
        <template #item-operation="item">
            <div class="btn-group btn-sm">

                <button type="button" class="btn btn-primary bi bi-eye btn-sm" data-bs-toggle="modal" data-bs-target="#editModal" @click="select(item)" />
              </div>
        </template>
         <template #item-magazzino="item">
            {{ magaz2Desc(item.stato) }}
        </template> 
     
      </DataTable>
      <ViewFornitore id="viewFornitore" :ubicazione="selectedubicazione"></ViewFornitore>
      <div class="modal fade modal-lg" id="editModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h1 class="modal-title fs-5" id="staticBackdropLabel"> Richiesta magazzino</h1>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="closeBtn"></button>
                </div>
                <form @submit.prevent="save">
                <div class="modal-body">
                  <VeicoloDettaglio v-if="selectedItem && selectedItem.lavoro.veicolo" :veicolo="selectedItem.lavoro.veicolo"/>
                        
                        <div class="mb-3 mt-3">
                            <label for="descrizione" class="form-label">Descrizione (obbligatorio)</label>
                            <textarea class="form-control" v-if="selectedItem" v-model="selectedItem.richiesta" disabled></textarea>
                        </div>
                        <div class="mb-3">
                            <label for="magazzino" class="form-label">Magazzino</label>
                            <select class="form-select" aria-label="Default select example" name="magazzino" v-if="selectedItem" v-model="selectedItem.stato">
                              <option value="R">Richiesto</option>
                              <option value="O">Ordinato</option>
                              <option value="A">Arrivato</option>
                            </select>
                        </div>

                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Chiudi</button>
                  <button type="submit" class="btn btn-primary" @click="salva">Salva</button>
                 
                </div>
            </form>
              </div>
            </div>
          </div>


    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import lavoriService from '@/services/lavori.service';
  import VeicoloDettaglio from '@/components/VeicoloDettaglio.vue';
  import magazzinoService from '@/services/magazzino.service';
  import ViewFornitore from '@/components/ViewFornitore.vue';

  export default {

    name: 'MagazzinoView',
    components: {
        VeicoloDettaglio, ViewFornitore
    },
    created(){
     
    },
    data() {
        return {
        loading_:true,
        searchValue: "",
        headers: [
          { text: "Telaio", value: "lavoro.veicolo.telaio"},
          { text: "Targa", value: "lavoro.veicolo.targa"},
          { text: "Marca", value: "lavoro.veicolo.marca"},
          { text: "Modello", value: "lavoro.veicolo.modello"},
          { text: "Lavorazione", value: "lavoro.lavorazione.descrizione"},
          { text: "Data Richiesta", value: "dataRichiesta", sortable: true},
          { text: "Stato Magazzino", value: "magazzino"},
          { text: "Stato Lavoro", value: "statoLavoro"},
          { text: "Ext", value: "esterno"},
          { text: "", value: "operation"}
        ],
        items: [],

        searchFields: ["lavoro.veicolo.telaio", "lavoro.veicolo.targa", "lavoro.veicolo.marca", "lavoro.veicolo.modello", "lavoro.lavorazione.descrizione"],

        selectedItem: null,
        selectedubicazione: null,

        filterOptions: []
        }
    },
    mounted(){
        this.fetchData();
    },
    computed : {
      /*roleId(){
        return this.$store.state.auth.user.role.id;
      }*/
    }, 
    methods : {
        fetchData(){
          magazzinoService.getRichieste().then(response => {
            this.items = response.data;
            this.items.forEach(it => {it.statoLavoro = it.lavoro.dataFine ? "Terminato" : (it.lavoro.dataInizio ? "In corso" : "Programmato")});
            this.loading_ = false;
      })
        },

        magaz2Desc(code){
            return lavoriService.magazzCode2Descr(code);
        },

        select(item){
          this.selectedItem = item;
          if (item.lavoro.ubicazione) this.selectedubicazione = item.lavoro.ubicazione;
        },

        removeFromList(i){
          this.items.splice(this.items.indexOf(i), 1);
        },

        bodyRowClassNameFunction(item){
            if (item.stato == "R") return 'richiesto-row';
            if (item.stato == "O") return 'ordinato-row';
            return 'arrivato-row';
        },

        salva(){
            magazzinoService.updateRichiesta(this.selectedItem.id, this.selectedItem.stato).then(() => {
                document.getElementById('closeBtn').click();
                this.loading_ = true;
                this.fetchData();
            })
        },

        addFilter(filtro){
          console.log(this.filterOptions);
          console.log(filtro);
          this.filterOptions.pop();
          if (filtro)
            this.filterOptions.push({
              field: 'stato',
              comparison: '=',
              criteria: filtro,
            });
        }
    }
  }
  </script>
  <style>

.richiesto-row  {
  --easy-table-body-row-background-color: #f8d7da;;
  --easy-table-body-row-font-color: #842029;
}

.ordinato-row  {
  --easy-table-body-row-background-color: #fff3cd;;
  --easy-table-body-row-font-color: #664d03;;
}

.arrivato-row  {
  --easy-table-body-row-background-color: #d1e7dd;
  --easy-table-body-row-font-color: #0f5132;;
}
</style>