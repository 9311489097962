import axios from 'axios'


const API_URL = process.env.VUE_APP_APIBASEURL;

class AuthService {
  login(user) {
    const params = new URLSearchParams();
    params.append("username", user.username);
    params.append("password", user.password);
    return axios
      .post(API_URL + 'token', params)
      .then(response => {
        console.log(response.data);
        if (response.data.access_token) {
          localStorage.setItem('token', response.data.access_token);
        }
        if (response.data.user_info){
          localStorage.setItem('user', JSON.stringify(response.data.user_info))
        }

        return response.data;
      });
  }

  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
  }

  authHeader() {
    let token = localStorage.getItem('token');
  
    if (token) {
      return { Authorization: 'Bearer ' + token };
    } else {
      return {};
    }
  }

  requestReset(mailAddr){
    return axios.post(API_URL + 'password/reset', {
      email: mailAddr
    });
  }

  actualReset(token_, newPassword){
    return axios.patch(API_URL + 'password/reset', {
      token: token_,
      password: newPassword
    });
  }

  setToken(_token){
    console.log("Try to get me with token:");
    console.log(_token);
    return axios.get(API_URL + 'me', {headers : { Authorization: 'Bearer ' + _token }}).then(response => {
      if (response.data){
        localStorage.setItem('token', _token);
        localStorage.setItem('user', JSON.stringify(response.data))
      }
      return response.data;
    });
  }

  /*
  register(user) {
    return axios.post(API_URL + 'signup', {
      username: user.username,
      email: user.email,
      password: user.password
    });
  }
  */
}

export default new AuthService();
  
